/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle, PriceWrap, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Antikorozní nástřik podvozku a dutin "}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--50 pt--60" name={"uvod"}>
          
          <ColumnWrap className="column__flex --center el--1 pb--0 pl--0 pr--0 pt--0 flex--center" style={{"maxWidth":1280}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 pb--0 pt--0" anim={"5"} animS={"5"} style={{"maxWidth":900}}>
              
              <Title className="title-box fs--72" content={"Antikorozní nástřik podvozku a dutin&nbsp;"}>
              </Title>

              <Text className="text-box fs--18" content={"Provádíme vysoce kvalitní antikorozní nástřik podvozku a dutin na osobních vozidlech i dodávkách.<br>Antikorozní ochranu podvozku aplikujeme již více než 10 let a máme stovky spokojených zákazníku kteří se k nám rádi vracejí.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"obsah-1"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Text className="text-box" content={"Nabízíme antikorozní nástřik podvozku přípravkem vyrobeným na gumoasfaltové bázi. Dutiny ošetřujeme přípravkem na bázi vosku. Oba materiály mají universální přilnavost a po čase jsou snadno obnovitelné. Můžete se u nás objednat v průběhu celého roku a pokud bydlíte do 10 km, odvezeme vás po převzetí vozidla zdarma. Častou otázkou majitelů vozidel je, zda má antikorozní nástřik v dnešní době ještě smysl. Odpověď zní ano, jelikož i výrobci automobilů hledí na své produkty „spotřebně“."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"zgsmvilnti"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Antikorozní ochrana podvozku a dutin prodlužuje životnost vašeho vozu o mnoho let."}>
              </Title>

              <Subtitle className="subtitle-box" content={"Jaký je pracovní postup"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Podvozek se nejprve důkladně očistí drátěným kartáčem, omyje tlakovou vodou a poté nechá úplně vyschnout. Karoserie je přitom zakryta fólií proti nečistotám. Demontují se kola a veškeré plastové kryty okrajů karoserie. Elektroinstalace, svorkovnice, senzory ABS a pryžové části podvozku se zalepí krycí papírovou páskou. Odřená a otlučená místa se pak opraví a ošetří zinkem. Pak následuje ochranný nástřik podvozku třemi vrstvami, což se provádí vysokým tlakem bez použití vzduchu. Celý proces od očištění podvozku až po antikorozní nástřik trvá obvykle dva dny. Tuto dobu lze zkrátit na pouhý jeden den tím, že si zákazník provede umytí a očištění podvozku předem sám. Při první variantě může zákazník využít služby v podobě odvozu do 10 km zdarma."}>
              </Text>

              <Subtitle className="subtitle-box" content={"Jaké je složení nástřikové hmoty"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"K antikoroznímu ošetření podvozku automobilů se používá kvalitní chemie vyráběná známou firmou Würth. Hmota je vyrobena na gumoasfaltové bázi, která spolehlivě chrání podvozek před kamínky odletujícím od kol. Podvozek je také dokonale ochráněn před účinky posypové soli a před korozí. Zároveň tato gumoasfaltová vrstva slouží jako částečné odhlučnění."}>
              </Text>

              <Subtitle className="subtitle-box" content={"Ošetření dutin"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Antikorozní nástřik se provádí nejen na podvozku, ale aplikuje se i do všech kapes, záhybů a dutin. K tomuto ošetření se používá přípravek na bázi vosku, který zateče do všech skrytých míst a po zaschnutí si zachová elasticitu při teplotách od minus 20 do plus 60° C. Tento antikorozní nástřik má zvláštní kapilární vlastnosti – infiltruje vlhkost, zabraňuje šíření koroze případně již napadených částí podvozku a karoserie vozidla."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"2s46cxhf4h6"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Malé vozidla ( Kia Picanto, Daewoo matiz, Suzuki Swift, atd )&nbsp;"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"Od 7500,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"střední vozidla ( Volkswagen golf, Škoda Octavia,&nbsp; atd&nbsp;"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"od 8500,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Velké vozidla&nbsp; ( Volkswagen Passat, Mazda 6, atd"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"od 9000,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"SUV a 4x4&nbsp;"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"Od 11500,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Dodávky , velké pickupy a&nbsp; terénní vozidla s podvozkovým rámem&nbsp; nebo obytné vozidla&nbsp;<br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"od 13000 - 20000&nbsp; ,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"tlu4xn8j66"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--20 lh--12" content={"Konečná cena je závislá na konkrétním typu vozidla. Uvedené ceny jsou konečné, včetně DPH."}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"dh29n1h28dp"}>
        </Column>


        <Column className="css-1odcdv6 css-42e4bw pb--80 pt--80" name={"kontakt"} parallax={false} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=3000x_.jpeg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--72 mt--16" style={{"maxWidth":966}} content={"<span style=\"color: white;\">Máte dotaz?</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 fs--22" url={"https://autoservisblaza.cz/kontaktni-formular/"} href={"https://autoservisblaza.cz/kontaktni-formular/"} content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-custom-2);\">Autoservis Bláža Ostrava - Radvanice&nbsp;</span><br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-custom-2);\">Pro Vaše auto uděláme vše, co budeme moct.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">O nás</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-custom-2);\">Jsme Malý a nezávislý autoservis osobních i užitkových vozidel. Měření a seřízení geometrie kol Servis čištění a plnění klimatizací Rychloservis Pneuservis Nástřiky podvozku a dutin Strojní regenerace filtru pevných částic DPF a jiné.&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-custom-2);\">+420 777 321 587 Lihovarská 1378/11, Ostrava - Radvanice Areál Bánských strojíren ( BASTRO )&nbsp;</span><br>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-custom-2);\">IČ: 63471710</span><span style=\"color: white;\"><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}